// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function guide() {
  var index = 0;
  var context = {};
  var tour = [];
  var iconNumber = 0;
  const {guideBox} = window;

  // data
  if (!isset(user.guide)) user.guide = [];
  $.each(data.guide, function (id, row) {
    context[id] = row.tour;
    if (!inArray(row.id, user.guide)) iconNumber++;
  });

  // button
  var btn = $(
    '<button id="guideBtn" type="button" class="btn btn-light"><i class="icon fa-regular fa-chalkboard-teacher fa-fw"></i></button>'
  )
    .insertAfter('#buttons > span:first')
    .addTooltip(parameter.guide.label);
  if (iconNumber)
    btn.number = $(
      '<span class="iconNumber">' + iconNumber + '</span>'
    ).appendTo(btn);
  contextMenu(
    btn,
    context,
    function (id) {
      var row = data.guide[id];
      ajax({
        object: 'guide',
        send: {where: {tour: row.tour}},
        callback: function (xhr) {
          tour = xhr.ids;
          index = 0;
          btn.addClass('active');
          if (guideBox)
            guideBox.removeClass('d-none').addClass('d-inline-block');
          else
            window.guideBox = $('<div id="guideBox"></div>').appendTo(
              document.body
            );
          next();
          if (!inArray(row.id, user.guide)) {
            user.guide.push(row.id);
            ajax({
              object: 'user',
              id: user.id,
              post: true,
              send: {guide: user.guide},
            });
            if (btn.number) {
              iconNumber--;
              btn.number.text(iconNumber);
            }
          }
        },
      });
    },
    null,
    click
  );

  // next step
  var next = function (add, afterTimeout) {
    if (add) index++;
    var step = data.guide[tour[index]];
    if (!step) return;

    // pre click
    if (step.preClick && !afterTimeout) $(step.preClick).trigger(click);

    // timeout
    if (step.timeout && !afterTimeout) {
      setTimeout(function () {
        next(false, true);
      }, step.timeout * 1000);
      return;
    }

    // content
    $('.marked').removeClass('marked');
    guideBox.empty().attr('class', '');
    guideBox
      .append('<div class="title">' + step.title + '</div>')
      .append('<div>' + step.text + '</div>');
    var closeBtn = $('<span class="close link">Beenden</span>')
      .appendTo(guideBox)
      .on(click, function () {
        btn.children().removeClass('active');
        $('.marked').removeClass('marked');
        guideBox.remove();
        delete window.guideBox;
      });
    if (tour[index + 1]) closeBtn.addClass('link');
    else closeBtn.addClass('button');
    if (tour[index + 1])
      $('<button class="next button">Weiter</button>')
        .appendTo(guideBox)
        .addTooltip('nächster Schritt [Pfeil rechts]')
        .on(click, function () {
          next(true);
        });
    if (tour[index - 1])
      $('<button class="prev button"><</button>')
        .appendTo(guideBox)
        .addTooltip('vorheriger Schritt [Pfeil links]')
        .on(click, function () {
          index--;
          next();
        });

    // position
    var pointer;
    var pm = {my: 'center', at: 'center', of: window};
    if (step.element) {
      var top = {};
      if (step.view) top = $('.view:visible');
      else top = $(document.body);
      var element = top.find(step.element).addClass('marked');
      if (!element[0]) return;
      pointer = $('<span class="pointer"></span>').appendTo(guideBox);
      pm.of = element;

      // left
      var offset = element.offset();
      if (offset.left < 50) {
        pm.my = 'left+5';
        pm.at = 'left';
        guideBox.addClass('left');
      }

      // top
      var height = element.height();
      // FIXME dim is not defined
      // eslint-disable-next-line no-undef
      if (height < dim.height - 200) {
        pm.my += ' top+10';
        pm.at += ' bottom';
      }
    }
    guideBox.position(pm);

    // pointer
    if (pointer) {
      var offsetBox = guideBox.offset();
      var widthBox = guideBox.width();
      // FIXME dim is not defined
      // eslint-disable-next-line no-undef
      offsetBox.right = dim.widthFull - offsetBox.left - widthBox;
      pointer.css({
        left: offset.left - offsetBox.left + element.width() / 2 - 3,
      });
    }

    // event to go further
    if (step.nextEvent) {
      var eventElement = element;
      if (step.nextEventElement) eventElement = $(step.nextEventElement);
      eventElement.stepId = step.id;
      if (step.nextEvent == 'click') {
        var event = step.nextEvent;
        if (event == 'click') event = click;
        eventElement.one(event, function () {
          if (eventElement.stepId == tour[index]) next(true);
        });
      } else if (step.nextEvent == 'drag')
        eventElement.draggable({
          stop: function () {
            if (eventElement.stepId == tour[index]) next(true);
          },
        });
      else if (step.nextEvent == 'resize')
        eventElement.resizable({
          stop: function () {
            if (eventElement.stepId == tour[index]) next(true);
          },
        });
    }
  };
}
